import { Modal } from "antd";
import React from "react";
import { CkModal } from "../../CkUI";
interface IProps {
  videoUrl: string;
  showVideo: boolean;
  setShowVideo: Function;
}
const VideoPlayerModal: React.FC<IProps> = ({
  videoUrl,
  showVideo,
  setShowVideo,
}) => {

  return (
    <CkModal
      zIndex={10031}
      title={"Reproducir video"}
      open={showVideo}
      footer={null}
      onCancel={() => setShowVideo(false)}
    >
      <video
        src={videoUrl}
        width="100%"
        controls
      >
      </video>
    </CkModal>
  );
};

export default VideoPlayerModal;
