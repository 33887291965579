import React, { FormEvent, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Layout,
  Menu,
  Form,
  Button,
  Spin,
  Popover,
  Modal,
  Avatar,
  Affix,
  message,
} from "antd";

import {
  DeleteOutlined,
  MoreOutlined,
  WarningOutlined,
  SaveOutlined,
  SendOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Coworker } from "../AccountSetup/Coworker";
import { User, UserNotification } from "shared/src/data-models/User";

import { useSelector, useDispatch } from "react-redux";
import {
  addCoworker,
  updateUser,
  deleteUser,
  fetchCoworkers,
  serviceSAS,
  fetchRoles,
  sendNotification,
  clearStatus,
} from "shared";
import {
  getUsers,
  getUsersLoading,
  getUserAction,
  getSasToken,
  getRoles,
  getUserError,
} from "../../stores/selectors";
import CoworkerIcon from "../../assets/Images/avatarDefault.svg";
import { ReactComponent as PlusIcon } from "../../assets/Images/icon_plus.svg";
import ConfirmationMessage from "../../app/Components/Modals/ConfirmationMessage";
import { ConfirmationModalData } from "../../app/models/modal";
import { CurrentUser } from "../../context/UserContext";
import { ExxonButton } from "../../app/Components/Form/ExxonButton";
import { ConfirmationModal } from "../../app/Components/Modals/ConfirmationModal";
import { MechanicDeleteIcon, SaveIcon } from "../../assets/SvgIcons";
import { useWindowSize, gtmAddLayer } from "../../app/Utilities";
import { CkMessage } from "../../CkUI";

interface SuccessModalData {
  visible: boolean;
  email: string;
  message: string;
}

interface IProps {
  workshopId: string;
}

export const WorkerDashboard: React.FC<IProps> = ({ workshopId }) => {
  const user = React.useContext(CurrentUser);

  const windowSize = useWindowSize();
  const coworkers = useSelector(getUsers);
  const loading: boolean = useSelector(getUsersLoading);
  const [action, actionSuccess] = useSelector(getUserAction);
  const [error, errorMessage] = useSelector(getUserError);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>("0");
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canEditRole, setCanEditRole] = useState<boolean>(false);
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [showModalMobile, setModalMobile] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [form] = Form.useForm();
  const roles = useSelector(getRoles);
  const [isMechanic, setIsMechanic] = useState<boolean>(true);

  const [currentCoworker, setCurrentCoworker] = useState<User>({
    id: "",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    userRoles: [],
    azureCode: "",
    dateOfBirth: undefined,
    photoUrl: "",
  });
  const [currentCoworkerKey, setCurrentCoworkerKey] = useState<number>(0);
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalData>({ visible: false, index: -1 });

  const [successModalData, setSuccessModalData] = useState<SuccessModalData>({
    visible: false,
    email: "",
    message: "",
  });

  const [intervalID, setIntervalId] = useState<any>(null);

  const sasToken: string = useSelector(getSasToken);

  React.useEffect(() => {
    setIntervalId(setInterval(getSasTokenAzure, 240000));
    dispatch(fetchRoles("ES-MX"));

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  React.useEffect(() => {
    //checkAccess();
    fetchData();
    checkMechanic();
  }, []);

  React.useEffect(() => {
    if (coworkers.length > 0) {
      if (currentCoworkerKey === -1) {
        setCurrentCoworker(coworkers[coworkers.length - 1]);
        setSelectedMenuKey((coworkers.length - 1).toString());
        setCurrentCoworkerKey(coworkers.length - 1);
      } else {
        setCurrentCoworker(coworkers[currentCoworkerKey]);
        setSelectedMenuKey(currentCoworkerKey.toString());
        form.resetFields();
      }
    } else {
      setSelectedMenuKey("new");
    }
  }, [coworkers]);

  React.useEffect(() => {
    checkAccess();
    if (!form.isFieldsTouched()) {
      form.resetFields();
    }
  }, [currentCoworker]);

  React.useEffect(() => {
    if (action === "added" && actionSuccess) {
      setSuccessModalData({
        ...successModalData,
        email: currentCoworker.email,
        visible: true,
      });
    }
  }, [action]);

  React.useEffect(() => {
    if (error && errorMessage === "Notification") {
      CkMessage({ type: "error", text: "Error al enviar la notificación al usuario"});
    } else if (!error && errorMessage === "Notification") {
      setSuccessModalData({
        visible: true,
        message: "El usuario ha sido creado",
        email: currentCoworker.email,
      });
    }

    if (error && errorMessage?.split("|")[0] === "Coworker") {
      CkMessage({ type: "error", text: errorMessage.split("|")[1]});
    }
  }, [error, errorMessage]);

  const fetchData = async () => {
    dispatch(fetchCoworkers(workshopId));
  };

  const clearUserStatus = () => {
    setSuccessModalData({ visible: false, email: "", message: "" });
    dispatch(clearStatus());
  };

  const checkMechanic = () => {
    let actualRole = user?.userRoles.find(
      (role) => role.workshopId === workshopId && role.roleCode === "MEC"
    );
    return actualRole ? setIsMechanic(true) : setIsMechanic(false);
  };

  const checkAccess = () => {
    if (user?.userRoles.find((role) => role.roleCode === "OWN") !== undefined) {
      setCanEdit(true);
      setCanEditRole(true);
      setCanAdd(true);
    } else if (
      user?.userRoles.find((role) => role.roleCode === "ADM") !== undefined
    ) {
      setCanAdd(true);
      if (
        currentCoworker.userRoles.find((role) => role.roleCode === "OWN") !==
        undefined
      ) {
        setCanEdit(false);

        setCanEditRole(false);
      } else {
        setCanEdit(true);

        if (currentCoworker.id === user.id) setCanEditRole(false);
        else {
          setCanEditRole(true);
        }
      }
    } else {
      setCanAdd(false);
      setCanEdit(false);
      setCanEditRole(false);
    }
  };
  const checkUserRole = (index: number) => {
    if (isMechanic) {
      return false;
    } else {
      if (user?.email === coworkers[index].email) {
        return false;
      }
      if (
        coworkers[index].userRoles.find((role) => role.roleCode === "OWN") !==
        undefined
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateWorker = async () => {
    try {
      await form.validateFields();
      dispatch(updateUser(currentCoworker)).then((result: any) => {
        CkMessage({ type: "success", text: "Datos actualizados correctamente."});
      });
    } catch {}
  };

  const addWorker = async () => {
    try {
      const email = currentCoworker.email;
      await form.validateFields();
      dispatch(addCoworker(currentCoworker)).then((result: any) => {
        // GOOGLE TAG
        gtmAddLayer({
          total_workers: coworkers.length + 1,
          workshop_ID: workshopId,
          event: "add_edit_user",
        });
      });
    } catch {}
  };

  const handleSaveChanges = () => {
    if (!currentCoworker.dateOfBirth) {
      CkMessage({ type: "error", text: "La fecha de nacimiento es obligatoria"});

      return;
    }
    if (currentCoworkerKey === -1) {
      addWorker();
    } else {
      updateWorker();
    }
  };

  const sendInvitation = (idUser: string) => {
    dispatch(
      sendNotification({
        UserId: parseInt(idUser),
        WorkshopId: parseInt(workshopId),
        NotificationCode: "INVITATION",
      })
    );
  };

  const setupAddWorker = () => {
    setSelectedMenuKey("new");
    setCurrentCoworker({
      id: "0",
      name: "",
      lastName: "",
      phone: "",
      email: "",
      userRoles: [{ workshopId: workshopId, roleCode: "MEC" }],
      azureCode: "",
      dateOfBirth: undefined,
      photoUrl: "",
    });
    setCurrentCoworkerKey(-1);
    form.resetFields();
    setModalMobile(true);
  };

  const RemoveCoworker = (index: number) => {
    setSelectedMenuKey("0");
    setCurrentCoworkerKey(0);
    setConfirmationModal({ visible: false, index: -1 });
    dispatch(deleteUser(coworkers[index].id)).then((result: any) => {
      // GOOGLE TAG
      gtmAddLayer({
        total_workers: coworkers.length - 1,
        workshop_ID: workshopId,
        event: "add_edit_user",
      });
    });
    setModalMobile(false);
  };

  const handleCoworkerChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setCurrentCoworker({ ...currentCoworker, [name]: value });
  };

  const handlePhotoChange = (photoUrl: string) => {
    setCurrentCoworker({ ...currentCoworker, photoUrl: photoUrl });
  };

  const handleToggle = async (checked: boolean) => {
    await setCurrentCoworker({
      ...currentCoworker,
      userRoles: [
        {
          workshopId: currentCoworker.userRoles[0].workshopId,
          roleCode: checked ? "ADM" : "MEC",
        },
      ],
    });
  };

  const handleDateOfBirthChange = (date: any) => {
    if (date) {
      const dateOfBirth = new Date(date);
      setCurrentCoworker({
        ...currentCoworker,
        dateOfBirth: dateOfBirth.toISOString(),
      });
    } else {
      setCurrentCoworker({
        ...currentCoworker,
        dateOfBirth: undefined,
      });
    }
  };

  const changeSelectedUser = (userIndex: number) => {
    setSelectedMenuKey(userIndex.toString());
    setCurrentCoworkerKey(userIndex);
    setCurrentCoworker(coworkers[userIndex]);
    form.resetFields();
    setModalMobile(true);
  };

  return (
    <>
      {window.screen.width > 992 ? (
        <>
          <Affix offsetTop={77}>
            <div
              style={{
                width: "100%",
                background: "#F8F8F8",
                height: "66px",
                marginTop: 15,
              }}
            >
              <Row
                style={{
                  padding: "0px max(2%, 15px) 15px",
                  alignItems: "center",
                }}
                gutter={[24, 24]}
              >
                <Col span={6}>
                  <h3
                    className="blue"
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    Mecánicos
                  </h3>
                </Col>
                <Col span={18}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Button
                      size="large"
                      className="button_blue div-row"
                      onClick={handleSaveChanges}
                      icon={
                        <SaveIcon
                          style={{
                            transform: "translate(0px, 3px)",
                          }}
                          className="img-icon-double"
                        />
                      }
                      hidden={!canEdit && !canEditRole}
                    >
                      Guardar
                    </Button>
                    <Button
                      size="large"
                      className="button_blue"
                      onClick={() => {
                        setConfirmationModal({
                          visible: true,
                          index: currentCoworkerKey,
                        });
                      }}
                      icon={<DeleteOutlined />}
                      style={{
                        marginRight: "16px",
                      }}
                      hidden={
                        currentCoworker.userRoles.findIndex(
                          (role) => role.roleCode === "OWN"
                        ) !== -1 ||
                        !canEdit ||
                        currentCoworker.email === user?.email
                      }
                    >
                      Borrar
                    </Button>
                    <Button
                      onClick={() => sendInvitation(currentCoworker.id)}
                      hidden={
                        selectedMenuKey === "new" ||
                        currentCoworker.azureCode !== "" ||
                        !canEdit
                      }
                      icon={<SendOutlined />}
                      style={{
                        border: "none",
                        backgroundColor: "rgba(0,0,0,0)",
                        color: "#2f66f2",
                        boxShadow: "none",
                        height: "49px",
                      }}
                    >
                      Volver a invitar
                    </Button>
                    <p
                      style={{
                        color: "#F6A314",
                        marginBottom: 0,
                        alignSelf: "center",
                        position:
                          windowSize.width < 1200 ? "absolute" : undefined,
                        paddingTop: windowSize.width < 1200 ? 75 : undefined,
                      }}
                      hidden={
                        selectedMenuKey === "new" ||
                        currentCoworker.azureCode !== ""
                      }
                    >
                      <WarningOutlined /> Esperando aceptación
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Affix>
          <Layout style={{ padding: "20px" }}>
            {/* <Spin spinning={loading} tip="Cargando..."> */}
            <Row>
              <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                {/* <Affix offsetTop={100}> */}
                <Row gutter={[0, 24]}>
                  <Menu
                    mode="inline"
                    selectedKeys={[selectedMenuKey]}
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  >
                    {coworkers.map((coworker, index) => (
                      <Menu.Item
                        key={index}
                        onClick={() => changeSelectedUser(index)}
                        style={{ backgroundColor: "white", height: "50px" }}
                      >
                        <Row style={{ lineHeight: "50px" }}>
                          <Col span={4}>
                            <Avatar
                              src={
                                coworker.photoUrl !== "" ? (
                                  `${coworker.photoUrl}?${sasToken}`
                                ) : (
                                  <UserOutlined
                                    style={{
                                      fontSize: "17px",
                                      transform: "translate(5px, 0px)",
                                    }}
                                  />
                                )
                              }
                              size={35}
                              style={{ backgroundColor: "#5696e2" }}
                            />
                          </Col>
                          <Col span={16}>
                            <p
                              style={{
                                fontFamily: "Emprint-regular",
                                fontSize: "16px",
                                margin: "0",
                                color: "#2C6FD1",
                                lineHeight: "26px",
                                marginTop: "6px",
                              }}
                            >
                              {coworker.name}
                            </p>
                            <p
                              style={{
                                fontFamily: "Emprint-regular",
                                fontSize: "12px",
                                margin: "0",
                                color: "#6D6E71",
                                lineHeight: "8px",
                              }}
                            >
                              {
                                roles.find(
                                  (role) =>
                                    role.roleCode ===
                                    coworker.userRoles[0].roleCode
                                )?.roleName
                              }
                            </p>
                          </Col>
                          <Col span={2}>
                            <WarningOutlined
                              style={{ color: "#F6A314" }}
                              hidden={coworker.azureCode !== ""}
                            />
                          </Col>

                          <Col span={2}>
                            {checkUserRole(index) ? (
                              <Popover
                                content={
                                  <Button
                                    onClick={() => {
                                      setConfirmationModal({
                                        visible: true,
                                        index: index,
                                      });
                                    }}
                                    icon={<DeleteOutlined />}
                                    style={{
                                      border: "none",
                                      backgroundColor: "rgba(0,0,0,0)",
                                      color: "#2C6FD1",
                                      boxShadow: "none",
                                    }}
                                  >
                                    Borrar
                                  </Button>
                                }
                                trigger="click"
                                placement="right"
                              >
                                <MoreOutlined />
                              </Popover>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Menu.Item>
                    ))}
                    <Menu.Item
                      key={"new"}
                      onClick={setupAddWorker}
                      hidden={!canAdd}
                    >
                      <Row>
                        <Col
                          span={4}
                          style={{ color: "#2f66f2" }}
                          className="div-row"
                        >
                          <PlusOutlined
                            style={{
                              //fontSize: "30px",
                              color: "#2f66f2",
                              marginTop: "5px",
                              marginRight: "25px",
                            }}
                            className="plus-agenda-icon"
                          />
                          Agregar empleado
                        </Col>
                      </Row>
                    </Menu.Item>
                  </Menu>
                </Row>
              </Col>
              <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                <Coworker
                  HandleChange={handleCoworkerChange}
                  HandleToggle={handleToggle}
                  HandleDateChange={handleDateOfBirthChange}
                  HandlePhotoChange={handlePhotoChange}
                  canEditEmail={selectedMenuKey === "new"}
                  form={form}
                  user={currentCoworker}
                  sasToken={sasToken}
                  canEdit={canEdit}
                  canEditRole={canEditRole}
                  style={{ width: "85%", margin: "auto" }}
                />
              </Col>
            </Row>
            {/* </Spin> */}
          </Layout>

          <ConfirmationModal
            title="Borrar datos del mecánico"
            message="¿Estás seguro de que quieres eliminar este perfil?"
            icon={<MechanicDeleteIcon />}
            onOk={() => RemoveCoworker(confirmationModal.index)}
            onCancel={() => {
              setConfirmationModal({ visible: false, index: -1 });
            }}
            visible={confirmationModal.visible}
          />

          <Modal
            style={{ color: "#454545", textAlign: "center" }}
            visible={successModalData.visible}
            width={window.screen.width > 992 ? "30%" : "100%"}
            centered
            footer={null}
            onCancel={clearUserStatus}
            zIndex={2600}
          >
            <SendOutlined
              style={{
                fontSize: "4rem",
                color: "lightgray",
                marginTop: "3rem",
              }}
            />
            <h6
              className="blue"
              style={{
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              Invitación enviada
            </h6>
            <p className="standar">
              {successModalData.message} <br />
              Se envió una nueva invitación a <br />
              <b style={{ marginTop: "1rem" }}>{successModalData.email}</b>
            </p>
            <br />
            <Button
              size="large"
              className="button_blue"
              style={{
                width: "50%",
                color: "white",
                backgroundColor: "#2f66f2",
                marginTop: "3rem",
                marginBottom: "1rem",
              }}
              onClick={clearUserStatus}
            >
              Cerrar
            </Button>
          </Modal>
        </>
      ) : (
        <>
          <Layout style={{ padding: "3%" }}>
            <Row gutter={[16, 2]} justify="center" className="web">
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <h3 className="blue">Mecánicos</h3>
              </Col>
              <Divider />
            </Row>
            <div className="site-card-wrapper" style={{ padding: "3%" }}>
              <Row gutter={[16, 16]}>
                {coworkers.map((coworker, index) => (
                  <Col span={24} key={index}>
                    <Row justify="center" align="middle" gutter={[12, 12]}>
                      <Col span={24}>
                        <Row style={{ background: "white" }} gutter={[24, 24]}>
                          <Col
                            span={4}
                            onClick={() => changeSelectedUser(index)}
                          >
                            <Avatar
                              style={{
                                margin: "2% auto",
                                backgroundColor: "#5696e2",
                              }}
                              src={
                                coworker.photoUrl !== "" ? (
                                  `${coworker.photoUrl}?${sasToken}`
                                ) : (
                                  <UserOutlined style={{ fontSize: "17px" }} />
                                )
                              }
                              shape="circle"
                              size={40}
                            />
                          </Col>
                          <Col
                            span={16}
                            onClick={() => changeSelectedUser(index)}
                          >
                            <Row>
                              <span
                                style={{
                                  color: "#454545",
                                  fontFamily: "Emprint-regular",
                                  fontSize: "16px",
                                }}
                              >
                                {coworker.name}
                              </span>
                            </Row>
                            <Row>
                              <span
                                style={{
                                  color: "#939598",
                                  fontFamily: "Emprint-regular",
                                  fontSize: "13px",
                                }}
                              >
                                {
                                  roles.find(
                                    (role) =>
                                      role.roleCode ===
                                      coworker.userRoles[0].roleCode
                                  )?.roleName
                                }
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            {checkUserRole(index) ? (
                              <Popover
                                content={
                                  <Button
                                    size="large"
                                    className="button_blue"
                                    onClick={() => {
                                      setConfirmationModal({
                                        visible: true,
                                        index: index,
                                      });
                                    }}
                                    icon={<DeleteOutlined />}
                                  >
                                    Borrar
                                  </Button>
                                }
                                trigger="click"
                                placement="right"
                              >
                                <MoreOutlined
                                  style={{
                                    fontSize: "25px",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translate(0, -50%)",
                                  }}
                                />
                              </Popover>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
            {canAdd ? (
              <Affix offsetBottom={0} style={{ width: "100%" }}>
                <Row
                  align="middle"
                  gutter={[12, 12]}
                  style={{ background: "#F8F8F8", margin: 0 }}
                >
                  <Col span={24}>
                    <Button
                      size="large"
                      className="button_blue"
                      icon={
                        <PlusIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "23px",
                            transform: "translate(23px, -50%)",
                            filter: "grayscale(100) contrast(100) invert(1)",
                          }}
                        />
                      }
                      style={{
                        width: "100%",
                      }}
                      onClick={setupAddWorker}
                    >
                      Agregar empleado
                    </Button>
                  </Col>
                </Row>
              </Affix>
            ) : (
              <></>
            )}
          </Layout>

          <Modal
            style={{ color: "#0C479D" }}
            className="coworker-modal"
            title={null}
            visible={showModalMobile}
            width={"100%"}
            footer={null}
            onCancel={() => {
              setModalMobile(false);
            }}
            closable
            destroyOnClose
            forceRender
            // cancelButtonProps={{ style: { display: "none" } }}
            centered
            zIndex={2500}
            bodyStyle={{ padding: "15px max(2%, 15px)" }}
          >
            <Spin spinning={loading} tip="Cargando...">
              <Row style={{ marginTop: "5%" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <p
                    style={{
                      color: "#F6A314",
                      marginTop: "3px",
                      fontSize: "16px",
                    }}
                    hidden={
                      selectedMenuKey === "new" ||
                      currentCoworker.azureCode !== ""
                    }
                  >
                    <WarningOutlined /> Esperando aceptación
                  </p>
                </Col>
              </Row>
              <Coworker
                HandleChange={handleCoworkerChange}
                HandleToggle={handleToggle}
                HandleDateChange={handleDateOfBirthChange}
                HandlePhotoChange={handlePhotoChange}
                canEditEmail={selectedMenuKey === "new"}
                form={form}
                user={currentCoworker}
                sasToken={sasToken}
                canEdit={canEdit}
                canEditRole={canEditRole}
              />
              <Affix offsetBottom={0}>
                <Row
                  gutter={[0, 12]}
                  style={{
                    background: "#F8F8F8",
                    marginTop: 15,
                    padding: "0 5px",
                  }}
                >
                  {selectedMenuKey === "new" ||
                  currentCoworker.azureCode !== "" ||
                  !canEdit ? (
                    <></>
                  ) : (
                    <Col span={24}>
                      <Button
                        size="large"
                        className="button_blue"
                        onClick={() => sendInvitation(currentCoworker.id)}
                        style={{ width: "100%" }}
                      >
                        Volver a invitar
                      </Button>
                    </Col>
                  )}

                  {canEdit && canEditRole ? (
                    <Col span={24}>
                      <Button
                        size="large"
                        className="button_blue div-row"
                        onClick={handleSaveChanges}
                        style={{ width: "100%" }}
                      >
                        <span
                          style={{
                            width: "100%",
                          }}
                        >
                          Guardar
                        </span>
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {currentCoworker.userRoles.findIndex(
                    (role) => role.roleCode === "OWN"
                  ) !== -1 ||
                  !canEdit ||
                  selectedMenuKey === "new" ? (
                    <></>
                  ) : (
                    <Col span={24}>
                      <ExxonButton
                        text="Eliminar"
                        onClick={() => {
                          setConfirmationModal({
                            visible: true,
                            index: currentCoworkerKey,
                          });
                        }}
                        style={{
                          width: "100%",
                          color: "#2f66f2",
                          background: "transparent",
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Affix>
            </Spin>
          </Modal>

          <ConfirmationMessage
            title="Eliminar usuario"
            message="¿Estás seguro de que quieres eliminar los datos este usuario?"
            onConfirm={() => RemoveCoworker(confirmationModal.index)}
            onClose={() => {
              setConfirmationModal({ visible: false, index: -1 });
            }}
            visible={confirmationModal.visible}
          />

          <Modal
            style={{ color: "#454545", textAlign: "center" }}
            visible={successModalData.visible}
            width={"100%"}
            footer={null}
            onCancel={clearUserStatus}
            zIndex={2600}
          >
            <SendOutlined
              style={{
                fontSize: "4rem",
                color: "lightgray",
                marginTop: "3rem",
              }}
            />
            <h2
              style={{
                color: "#2f66f2",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              Invitación enviada
            </h2>
            {successModalData.message} <br />
            Se envió una nueva invitación a <br />
            <strong style={{ marginTop: "1rem" }}>
              {successModalData.email}
            </strong>
            <br />
            <Button
              size="large"
              className="button_blue"
              style={{
                marginTop: "3rem",
                marginBottom: "1rem",
              }}
              onClick={clearUserStatus}
            >
              Cerrar
            </Button>
          </Modal>
        </>
      )}
    </>
  );
};
