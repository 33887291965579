import React from "react";
import { Col, Divider, Row, } from "antd";
import { useWindowSize } from "../../app/Utilities/WindowSize";
import {
  CustomerMailIcon,
  CustomerServicesIcon,
  GlobalIcon,
  WhatsappIcon,
} from "../../assets/SvgIcons";



export const Support: React.FC = () => {
  const windowSize = useWindowSize();
  return (
    <div style={{ padding: "15px max(2%, 15px)" }}>
      <h3 className="blue" style={{ margin: 0 }}>
        Soporte técnico
      </h3>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <p className="standar black"
        style={{
          width: windowSize.width > 992 ? "60%" : "100%",
          margin: 0,
        }}
      >
        Ponemos a tu disposición nuestros medios de contacto.
      </p>
      <h5 className="blue"
        style={{
          marginTop: 15,
        }}
      >
        Datos de contacto
      </h5>
      <Row
        style={{ marginTop: 15, marginBottom: 15 }}
        align="middle"
      >
        <Col flex="45px">
          <CustomerServicesIcon className="blue-filter img-icon-double" />{" "}
        </Col>
        <Col flex="auto">
          <a className="standar"
            style={{ lineHeight: "24px" }} href="tel:5522820800">
            55 2282 0800
          </a>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 15, marginBottom: 15 }}
        align="middle"
      >
        <Col flex="45px">
          <WhatsappIcon className="img-icon-double" />{" "}
        </Col>
        <Col flex="auto">
          <a className="standar"
            href="https://wa.me/525564359958"
            style={{ lineHeight: "24px", cursor: "pointer" }}
          >
            55 6435 9958
          </a>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 15, marginBottom: 15 }}
        align="middle"
      >
        <Col flex="45px">
          <CustomerMailIcon className="blue-filter  img-icon-double" />{" "}
        </Col>
        <Col flex="auto">
          <a className="standar"
            href="mailto:soporte@carker.com"
            style={{ lineHeight: "24px", cursor: "pointer" }}
          >
            soporte@carker.com.mx
          </a>
        </Col>
      </Row>
      <Row
        style={{ marginTop: 15, marginBottom: 15 }}
        align="middle"
      >
        <Col flex="45px">
          <GlobalIcon className="blue-filter img-icon-double" />{" "}
        </Col>
        <Col flex="auto">
          <a className="standar"
            href="https://www.carker.com.mx"
            style={{ lineHeight: "24px", cursor: "pointer" }}
          >
            www.carker.com.mx
          </a>
        </Col>
      </Row>
    </div>
  );
};
